import { autoUpdate, useFloating, FloatingPortal, offset, size, useDismiss, useInteractions } from '@floating-ui/react'
import { HeadingSm } from './Heading'
import { Icon } from './Icon'

import IconExpand from '/images/icons/expand.raw.svg'

import styles from './ExpandableText.css'

export function ExpandableText({ title, text, children }) {

  const { isOpen, setIsOpen, getFloatingProps, getReferenceProps } = useExpandable()

  return (
    <>
      <button
        className={styles.button}
        {...getReferenceProps({
          onClick: () => setIsOpen(!isOpen) })
        }
      >
        <span>{children}</span>
        &nbsp; <span className={styles.icon}><Icon icon={IconExpand} /></span>
      </button>
      {isOpen && (
        <FloatingPortal>
          <div
            data-style-context="blue"
            className={styles.expand}
            {...getFloatingProps()}
          >
            <div className={styles.heading}>
              <HeadingSm h={3} {...{ title }} />
              <button aria-label={title} data-x='open-expandable-text' onClick={() => setIsOpen(false)} className={styles.closeButton}><Icon icon={IconExpand} /></button>
            </div>
            <p className={styles.text}>{text}</p>
          </div>
        </FloatingPortal>
      )}
    </>
  )
}

function useExpandable() {
  const [isOpen, setIsOpen] = React.useState(false)

  const { refs, floatingStyles, context } = useFloating({
    placement: 'bottom-start',
    open: isOpen,
    onOpenChange: setIsOpen,
    whileElementsMounted: autoUpdate,
    middleware: [
      offset({ mainAxis: -24 }),
      size({
        apply({ availableWidth, elements }) {
          Object.assign(elements.floating.style, {
            width: `255px`,
            maxWidth: `${availableWidth}px`
          })
        },
      })
    ]
  })

  const dismiss = useDismiss(context)
  const { getReferenceProps, getFloatingProps } = useInteractions([dismiss])

  return {
    isOpen,
    setIsOpen,
    getFloatingProps: (args = {}) => getFloatingProps({
      ...args,
      ref: refs.setFloating,
      style: floatingStyles,
    }),
    getReferenceProps: (args = {}) => getReferenceProps({
      ...getReferenceProps({
        ...args,
        ref: refs.setReference
      }),
    })
  }
}
